import React from 'react';
import { Flex } from "@chakra-ui/react"

import { Timeline } from "../components/sections/Timeline"


export const Work = () => {
          
        return (
            <Flex alignContent="center">
                <Timeline/>
            </Flex>
        )
      
    }